import { ApiResponse, createAuthenticatedApiCall } from '../utils/apiCall';
import type { ApiRequestConfig } from './models/ApiRequestConfig';

// Response type matching API structure
interface ValidateTariffResponse {
  data: {
    eligible: boolean;
    // Add other response fields here
  };
  Message?: string;
  StatusCode?: string;
  errors?: string;
}

export type ValidateTariff = ApiResponse<ValidateTariffResponse>;

export const validateTariff = (config: ApiRequestConfig): ValidateTariff => {
  const { customerId, apis } = config;

  const queryParams = new URLSearchParams({
    product: 'TravelInsurance',
    msisdn: customerId,
  });

  return createAuthenticatedApiCall<ValidateTariffResponse>({
    baseUrl: `${apis.methods.validateTariff}?${queryParams}`,
    method: 'GET',
    config,
  });
};
