import { ApiResponse, createAuthenticatedApiCall } from '../utils/apiCall';
import type { ApiRequestConfig } from './models/ApiRequestConfig';

// Request/Response types
interface SendInsuranceDocumentsRequest {
  proposalId: string;
  recipients: string[];
}

interface SendInsuranceDocumentsResponse {
  statusText: string;
}

export interface SendInsuranceDocumentsConfig extends ApiRequestConfig {
  proposalId: string;
  recipients: string[];
}

export type SendInsuranceDocuments =
  ApiResponse<SendInsuranceDocumentsResponse>;

export const sendInsuranceDocuments = (
  config: SendInsuranceDocumentsConfig,
): SendInsuranceDocuments => {
  const { apis, proposalId, recipients } = config;

  const queryParams = new URLSearchParams({
    product: 'TravelInsurance',
  });

  const body: SendInsuranceDocumentsRequest = {
    proposalId,
    recipients,
  };

  return createAuthenticatedApiCall<SendInsuranceDocumentsResponse>({
    baseUrl: `${apis.methods.sendInsuranceDocuments}?${queryParams}`,
    method: 'POST',
    body,
    config,
  });
};
