import { ApiResponse, createApiCall } from '../utils/apiCall';
import { ApiRequestConfig } from './models/ApiRequestConfig';

interface AccessTokenResponse {
  access_token: string;
  refresh_token: string;
  errors?: string;
}

export type FetchAccessToken = ApiResponse<AccessTokenResponse>;

export const fetchAccessToken = (
  config: ApiRequestConfig,
): FetchAccessToken => {
  const { apis } = config;

  const headers = new Headers();
  headers.append('Host', apis.methods.accessToken['hostname']);
  headers.append('Content-Type', 'application/x-www-form-urlencoded');

  const params = new URLSearchParams();
  params.append('grant_type', 'client_credentials');
  params.append('scope', 'openid');
  params.append('client_id', apis.auth.clientId);
  params.append('client_secret', apis.auth.clientSecret);

  return createApiCall<AccessTokenResponse>({
    baseUrl: apis.methods.accessToken,
    method: 'POST',
    headers,
    // use URL-encoded string as body for Content-Type: application/x-www-form-urlencoded
    body: params.toString(),
    config,
  });
};
