import { ApiResponse, createAuthenticatedApiCall } from '../utils/apiCall';
import type { ApiRequestConfig } from './models/ApiRequestConfig';

export type FetchCustomerDataFromPartnerResponse = unknown;
export type FetchCustomerDataFromPartner =
  ApiResponse<FetchCustomerDataFromPartnerResponse>;

export const fetchCustomerDataFromPartner = (
  config: ApiRequestConfig,
): FetchCustomerDataFromPartner => {
  const { customerId, apis } = config;

  const queryParams = new URLSearchParams({
    msisdn: customerId,
    product: 'TravelInsurance',
  });

  return createAuthenticatedApiCall<FetchCustomerDataFromPartnerResponse>({
    baseUrl: `${apis.methods.getCustomerDataFromPartner}?${queryParams}`,
    method: 'GET',
    credentials: 'include',
    config,
  });
};
