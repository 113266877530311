import { get } from 'svelte/store';
import { fetchAccessToken } from './fetchAccessToken';
import { refreshAccessToken } from './refreshAccessToken';
import { setTokens, tokenStore } from '../stores/auth-tokens';
import { isTokenExpired } from '../utils/tokenUtils';
import { ApiRequestConfig } from './models/ApiRequestConfig';

const handleRefreshToken = async (
  config: ApiRequestConfig,
): Promise<boolean> => {
  const currentTokens = get(tokenStore);
  if (!currentTokens?.refreshToken) return false;

  const response = await refreshAccessToken(config, currentTokens.refreshToken);

  if (response.status === 'success' && response.data) {
    setTokens({
      accessToken: response.data.access_token,
      refreshToken: response.data.refresh_token,
    });
    return true;
  }

  return false;
};

const handleNewToken = async (config: ApiRequestConfig): Promise<boolean> => {
  const response = await fetchAccessToken(config);

  if (response.status === 'success' && response.data) {
    setTokens({
      accessToken: response.data.access_token,
      refreshToken: response.data.refresh_token,
    });
    return true;
  }

  return false;
};

export const authenticatedFetch = async (
  url: string,
  options: RequestInit,
  config: ApiRequestConfig,
): Promise<Response> => {
  // load current token from store - might be empty or expired
  let currentTokens = get(tokenStore);

  // no tokens found in the store - issue a new JWT
  if (!currentTokens) {
    console.log('No tokens.. fetching a new token');
    const success = await handleNewToken(config);

    if (!success) {
      throw new Error('Failed to obtain new authentication tokens');
    }

    // token from the store is expired - refresh JWT
  } else if (isTokenExpired(currentTokens.accessToken)) {
    console.log('Token expired.. refreshing it');

    const refreshSuccess = await handleRefreshToken(config);

    // refreshing the token failed - issue a new JWT
    if (!refreshSuccess) {
      console.log('Refreshing failed.. getting a new token');
      const newTokenSuccess = await handleNewToken(config);

      if (!newTokenSuccess) {
        throw new Error(
          'Failed to refresh or obtain new authentication tokens',
        );
      }
    }
  }

  // load current tokens again after handling retrieve/refresh logic
  currentTokens = get(tokenStore);

  if (!currentTokens) {
    throw new Error('No valid authentication tokens available');
  }

  // add authentication headers for outgoing request
  const headers = options.headers as Headers;
  headers.set('Authorization', `Bearer ${currentTokens.accessToken}`);

  return fetch(url, {
    ...options,
    headers,
  });
};
