import { ApiResponse, createAuthenticatedApiCall } from '../utils/apiCall';
import type { ApiRequestConfig } from './models/ApiRequestConfig';

export type FetchPhoneNumberResponse = {
  mobile_phone_number: string;
};

export type FetchPhoneNumber = ApiResponse<FetchPhoneNumberResponse>;

export const fetchPhoneNumber = (
  config: ApiRequestConfig,
): FetchPhoneNumber => {
  const { apis } = config;

  return createAuthenticatedApiCall<FetchPhoneNumberResponse>({
    baseUrl: apis.methods.getPhoneNumber,
    method: 'GET',
    credentials: 'include',
    config,
  });
};
