import type { FetchCustomerDataResponse } from './models/FetchCustomerDataResponse';
import { ApiResponse, createAuthenticatedApiCall } from '../utils/apiCall';
import type { ApiRequestConfig } from './models/ApiRequestConfig';

export type FetchCustomerData = ApiResponse<FetchCustomerDataResponse>;

export const fetchCustomerData = (
  config: ApiRequestConfig,
): FetchCustomerData => {
  const { customerId, apis } = config;

  const queryParams = new URLSearchParams({
    id: customerId,
    product: 'TravelInsurance',
  });

  return createAuthenticatedApiCall<FetchCustomerDataResponse>({
    baseUrl: `${apis.methods.getCustomerData}?${queryParams}`,
    method: 'GET',
    config,
  });
};
