import { ApiResponse, createApiCall } from '../utils/apiCall';
import type { ApiRequestConfig } from './models/ApiRequestConfig';

// Same response type as fetchAccessToken
interface AccessTokenResponse {
  access_token: string;
  refresh_token: string;
  errors?: string;
}

export type RefreshAccessToken = ApiResponse<AccessTokenResponse>;

export const refreshAccessToken = (
  config: ApiRequestConfig,
  refreshToken: string,
): RefreshAccessToken => {
  const { apis } = config;

  const headers = new Headers();
  headers.append('Host', apis.methods.accessToken['hostname']);
  headers.append('Content-Type', 'application/x-www-form-urlencoded');

  const params = new URLSearchParams({
    grant_type: 'refresh_token',
    refresh_token: refreshToken,
    scope: 'openid',
    client_id: apis.auth.clientId,
    client_secret: apis.auth.clientSecret,
  });

  return createApiCall<AccessTokenResponse>({
    baseUrl: apis.methods.accessToken,
    method: 'POST',
    headers,
    body: params.toString(),
    config,
  });
};
