import { ApiResponse, createAuthenticatedApiCall } from '../utils/apiCall';
import type { ApiRequestConfig } from './models/ApiRequestConfig';

interface HTTPCookieResponse {
  // Add actual response fields here
}

export type FetchHTTPCookie = ApiResponse<HTTPCookieResponse>;

export const fetchHTTPCookie = async (
  config: ApiRequestConfig,
): FetchHTTPCookie => {
  const { apis, code } = config;

  const queryParams = new URLSearchParams({
    code,
    isTest: 'false',
  });

  const response = await createAuthenticatedApiCall<HTTPCookieResponse>({
    baseUrl: `${apis.methods.httpCookie}?${queryParams}`,
    method: 'GET',
    credentials: 'include',
    config,
  });

  if (response.status === 'error' && response.error['ErrorCode'] === 'PA039') {
    return {
      status: 'error',
      error: new Error('token-expired'),
    };
  }

  return response;
};
