import { authenticatedFetch } from '../api/authenticatedFetch';
import { getUuid } from './getUuid';
import type { ApiRequestConfig } from '../api/models/ApiRequestConfig';

type HttpMethod = 'GET' | 'POST' | 'PUT' | 'DELETE';

export interface RequestConfig {
  baseUrl: string;
  method: HttpMethod;
  credentials?: RequestCredentials;
  headers?: Headers;
  body?: any;
  config: ApiRequestConfig;
}

export type ApiResponse<T> = Promise<{
  status: 'success' | 'error';
  data?: T;
  error?: unknown;
}>;
const handleApiResponse = async <T>(
  promise: Promise<Response>,
  isLocal?: boolean,
): ApiResponse<T> => {
  try {
    const response = await promise;

    if (!response.ok) {
      throw new Error(`${response.status} ${response.statusText}`);
    }

    // Only try to parse JSON if there's a content-type header indicating JSON
    const contentType = response.headers.get('content-type');
    let data;

    if (contentType?.includes('application/json')) {
      data = await response.json();
      isLocal && console.log('Fetched', response.url, ':\n', data);

      if (data.errors) {
        throw new Error(data.errors);
      }
    }

    return {
      status: 'success',
      data: data,
    };
  } catch (error) {
    isLocal && console.log('Unexpected error while handling response:', error);
    return {
      status: 'error',
      error: error,
    };
  }
};

export const createApiCall = async <T>(
  requestConfig: RequestConfig,
): ApiResponse<T> => {
  const {
    baseUrl,
    method,
    credentials,
    headers = new Headers(),
    body,
    config,
  } = requestConfig;

  const requestOptions: RequestInit = {
    method,
    headers,
    credentials,
    ...(body && {
      body:
        headers.get('Content-Type') === 'application/json'
          ? JSON.stringify(body)
          : body,
    }),
  };

  return handleApiResponse<T>(fetch(baseUrl, requestOptions), config.isLocal);
};

export const createAuthenticatedApiCall = async <T>(
  requestConfig: RequestConfig,
): ApiResponse<T> => {
  const {
    baseUrl,
    method,
    credentials,
    headers = new Headers(),
    body,
    config,
  } = requestConfig;

  headers.append('subscription-key', config.apis.auth.subscriptionKey);
  headers.append('correlationId', getUuid());
  headers.append('userPrincipalId', config.apis.auth.subscriptionKey);
  headers.append('Content-Type', 'application/json');

  const requestOptions: RequestInit = {
    method,
    headers,
    credentials,
    ...(body && {
      body:
        headers.get('Content-Type') === 'application/json'
          ? JSON.stringify(body)
          : body,
    }),
  };

  return handleApiResponse<T>(
    authenticatedFetch(baseUrl, requestOptions, config),
    config.isLocal,
  );
};
