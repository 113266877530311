import { writable } from 'svelte/store';

export interface TokenPair {
  accessToken: string;
  refreshToken: string;
}

export const tokenStore = writable<TokenPair | null>(null);

export const setTokens = (tokens: TokenPair) => {
  tokenStore.set(tokens);
};
