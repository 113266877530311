import type { FetchCoverOptionsResponse } from './models/FetchCoverOptionsResponse';

import type { ApiRequestConfig } from './models/ApiRequestConfig';
import { ApiResponse, createAuthenticatedApiCall } from '../utils/apiCall';

export type FetchCoverOptions = ApiResponse<FetchCoverOptionsResponse>;

export const fetchCoverOptions = (
  config: ApiRequestConfig,
): FetchCoverOptions => {
  const { customerId, apis } = config;

  const queryParams = new URLSearchParams({
    id: customerId,
    product: 'TravelInsurance',
  });

  return createAuthenticatedApiCall<FetchCoverOptionsResponse>({
    baseUrl: `${apis.methods.getCoverOptions}?${queryParams}`,
    method: 'GET',
    config,
  });
};
