export interface TokenPayload {
  sub?: string; // Subject (usually user ID)
  exp: number; // Expiration time (Unix timestamp in seconds)
  iat?: number; // Issued at time
  iss?: string; // Issuer
  aud?: string; // Audience
  [key: string]: any; // Allow additional fields
}

const decodeToken = (token: string): TokenPayload | null => {
  try {
    const parts = token.split('.');
    if (parts.length !== 3) {
      console.error('Invalid token format');
      return null;
    }

    const base64Url = parts[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(
      window
        .atob(base64)
        .split('')
        .map((c) => '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2))
        .join(''),
    );

    return JSON.parse(jsonPayload) as TokenPayload;
  } catch (error) {
    console.error('Error decoding token:', error);
    return null;
  }
};

export const isTokenExpired = (token: string): boolean => {
  if (!token) return true;

  try {
    const payload = decodeToken(token);

    if (!payload || !payload.exp) return true;

    const currentTime = Math.floor(Date.now() / 1000);

    return currentTime >= payload.exp;
  } catch (error) {
    console.error('Error checking token expiration:', error);
    return true;
  }
};
