import { ApiResponse, createAuthenticatedApiCall } from '../utils/apiCall';
import type { FetchCompanyDataResponse } from './models/FetchCompanyDataResponse';

export type FetchCompanyData = ApiResponse<FetchCompanyDataResponse>;

export const fetchCompanyData = (config): FetchCompanyData => {
  const { customerId, apis } = config;

  const queryParams = new URLSearchParams({
    id: customerId,
    product: 'TravelInsurance',
  });

  return createAuthenticatedApiCall<FetchCompanyDataResponse>({
    baseUrl: `${apis.methods.getCompanyData}?${queryParams}`,
    method: 'GET',
    config,
  });
};
