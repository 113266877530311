import { ApiResponse, createAuthenticatedApiCall } from '../utils/apiCall';
import type { ApiRequestConfig } from './models/ApiRequestConfig';

// Response type matching API structure
interface ValidateContractResponse {
  data: {
    hasActive: boolean;
    // Add other response fields here
  };
  Message?: string;
  StatusCode?: string;
  errors?: string;
}

export type ValidateContract = ApiResponse<ValidateContractResponse>;

export const validateContract = (
  config: ApiRequestConfig,
): ValidateContract => {
  const { customerId, apis } = config;

  const queryParams = new URLSearchParams({
    product: 'TravelInsurance',
    msisdn: customerId,
  });

  return createAuthenticatedApiCall<ValidateContractResponse>({
    baseUrl: `${apis.methods.validateContract}?${queryParams}`,
    method: 'GET',
    config,
  });
};
